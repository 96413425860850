:root {
    --app-main-color: #002651;
    --app-accent-color: #6CB2EB;
    --navbar-color: var(--app-main-color);
    --bs-font-sans-serif: Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Sofia Sans Condensed", sans-serif;
    font-weight: 700;
}

#songContent {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}
/* aby se v gridu nedalo omylem kliknout na volné místo vedle hvězdiček a tím aktivovat onRowClick */
.dx-datagrid .stars-rating-wrapper {
    height: 100%;
    width: 100%;
}

/* aby historie přehrávání nebyla moc dlouhá */
#playbackHistorySection .dx-list {
    max-height: 500px;
    overflow-y: auto;
}

/* aby sticky tlačítka v editoru byla až pod vrchním pruhem */
#chordProEditorTitle {
    top: 75px;
    background-color: #fff;
}
/* náhled textu z editoru je scrollovací a zabírá zhruba celou výšku obrazovky */
#chordProReaderScrollingWrapper {
    max-height: calc(100vh - 200px); /* cca 100px header a 100px spodní tlačítka uložit */
    overflow-y: auto;
    position: sticky;
    top: 90px;
}
/* aby prázdný výsledek validace zbytečně nezabíral místo, odstraní se mu okraj */
.dx-validationsummary:empty {
    margin-bottom: 0 !important;
}

/* Stránkování v PDF songu */
.paging-bar {
    position: relative;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    z-index: 200;
    padding-top: 10px;
    color: #000;
}

.paging-bar .dx-button .dx-icon,
.paging-bar .row .dx-button .dx-icon {
    color: #3490DC !important;
    font-size: 1.5rem;
}

/* Nastavení plátna v editaci */
.canvasEdit {
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0; 
    bottom: 200px;
    z-index: 100; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Nastavení pevné velikost plátna poznámek */
@media only screen and (max-width: 1930px) {
    .canvas-fixed-size {
        width: 1320px;
    }
}

@media only screen and (max-width: 1500px) {
    .canvas-fixed-size {
        width: 1180px;
    }
}

@media only screen and (max-width: 1450px) {
    .canvas-fixed-size {
        width: 1150px;
    }
}

@media only screen and (max-width: 1034px) {
    .canvas-fixed-size {
        width: 800px;
    }
}

@media only screen and (max-width: 778px) {
    .canvas-fixed-size {
        width: 530px;
    }
}

@media only screen and (max-width: 435px) {
    .canvas-fixed-size {
        width: 410px;
    }
}

@media only screen and (max-width: 385px) {
    .canvas-fixed-size {
        width: 360px;
    }
}

@media only screen and (max-width: 330px) {
    .canvas-fixed-size {
        width: 305px;
    }
}



.btn-active, .btn-active .dx-icon, .btn-active .dx-button-text, .dx-list-item-selected {
    color: #e3342f !important;
}

/* Aby nebyl dropdown item uprostřed */
.custom-dropdown-item .dx-button-content {
    width: 100%;
    justify-content: start !important;
    padding-left: 16px !important;
}

.touch-allow, 
.touch-allow .pdf-lyrics {
    /* touch-action: pan-y; */
    touch-action: auto;
    overflow-x: visible;
}

.touch-deny, 
.touch-deny .pdf-lyrics {
    touch-action: none;
    overflow-x: hidden;
}

.section-head-sticky-top {
    position: sticky;
    top: 75px;
    z-index: 1020;
    background: #fff;
}

#fullscreen .section-head-sticky-top {
    position: sticky;
    top: 59px;
    z-index: 1020;
    background: #fff;

}

.icon-black,
.icon-black .dx-button-content i {
    color: #000000 !important;
}

.icon-blue,
.icon-blue .dx-button-content i {
    color: #3490DC !important;
}

.icon-red,
.icon-red .dx-button-content i {
    color: #E3342F !important;
}

.btn-active-alt, .dx-list-item-selected {
    border: 1px solid #f3f2f0 !important;
    background: #3490DC !important;
    border-radius: 4px;
}

.btn-active-alt .dx-icon, .btn-active-alt .dx-button-text {
    color: #fff !important;
}

.pdf-lyrics {
    overflow-x: scroll;
    overflow-y: hidden;
}

/* Tlačítka pro stránkování PDF */
.paging-arrow-left .dx-button-content {
    justify-content: center;
    padding: 10px !important;
    border-radius: 8px;
    background: rgb(0, 38, 81, .1);
}

.paging-arrow-right .dx-button-content {
    justify-content: center;
    padding: 10px !important;
    border-radius: 8px;
    background: rgb(0, 38, 81, .1);
}


/* Tab panel */
.dx-tabpanel .dx-multiview-wrapper {
    background: transparent;
    border: none;
    padding: 10px 0;
}

.dx-tabpanel .dx-tabs-wrapper {
    background: transparent;
    border: none;;
}

.dx-tabpanel-tab {
    margin-bottom: 0 !important;
    border-bottom: 3px solid transparent;
    background-color: #fff;
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
    display: table;
    border-radius: 8px;
    background: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-item.dx-tab {
    border-radius: 8px;    
}

.dx-tabpanel-tab:hover, .dx-tab.dx-tab-selected {
    border-radius: 8px;
    -webkit-box-shadow: none;
    border-bottom: 3px solid #002651 !important;
}


/* Vyhledávací pole pro mobily v header */
.mobile-search {
    position: fixed;
    top: 70px;
    background: var(--navbar-color);
    z-index: 20;
}

/* Výraznější border v Checkboxu */
.dx-checkbox-icon {
    border-width: 2px;
    border-color: #ccc;
}

.dx-checkbox-checked .dx-checkbox-icon,
.dx-checkbox.dx-state-hover .dx-checkbox-icon,
.dx-checkbox.dx-state-focused .dx-checkbox-icon {
    border-width: 2px !important;
}