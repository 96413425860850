:root {
    --link-border-width: 5px;
    --header-height: 75px;
}

html, body, #root,
/* potřebujeme přepsat swal2 nastavení, proto přidána DISABLE třída */
/* bez toho by při vyskočení dialogu nebylo body na 100% a #page by tak byla velmi krátká */ 
body.swal2-height-auto-DISABLE.swal2-height-auto {
    height: 100% !important;
}

/* Header */
.navbar {
    background-color: var(--navbar-color);
    min-height: var(--header-height);
}
/* aby se v menu tlačítku měnila ikona */
.navbar-toggler.collapsed i.close {
    display: none;
}
.navbar-toggler:not(.collapsed) i.open {
    display: none;
}
/* aby menu tlačítko nemělo ošklivý okraj */
.navbar-toggler,
.navbar-toggler:focus {
    box-shadow: none;
    color: #fff;
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: var(--header-height) 0 0; /* Height of navbar */
    background-color: var(--navbar-color);

}
/* úzké menu používat jen na velkých obrazovkách (md a víc), na menších je totiž menu skryté */
@media (min-width: 768px) { 
    .compact .sidebar {
        max-width: 50px;
    }
    .compact main {
        margin-left: 50px;
    }
}
.sidebar-sticky {
    height: calc(100vh - var(--header-height));
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.sidebar .nav-link {
    padding: 0;
    line-height: 225%;
    border-left: var(--link-border-width) solid transparent;
}
.sidebar .nav-link.active {
    border-color: #fff;
}
.sidebar .nav-link,
.sidebar .nav-item.with-dx-button .dx-button-mode-contained .dx-icon,
#btnToggleSidebar {
    color: #fff;
    white-space: nowrap;
}
.sidebar .nav-link:hover,
.sidebar .nav-item.with-dx-button .dx-button-mode-contained:hover .dx-icon,
#btnToggleSidebar:hover {
    color: var(--app-accent-color);
    text-decoration: none;
}
.sidebar .nav-item.with-dx-button {
    width: 100%;
    text-align: left;
    border: none;
}
.sidebar .nav-item.with-dx-button .dx-button-content {
    display: flex;
    justify-content: flex-start;
}
.sidebar .nav-item.with-dx-button .dx-icon {
    height: auto;
    font-size: inherit;
    margin-right: 12px;
    margin-left: 0;
    line-height: 225%;
}
.sidebar .nav-item.with-dx-button .dx-icon.fa-fw {
    width: 1.25em;
}
.sidebar .nav-item.with-dx-button .dx-button-text {
    margin-left: 4px;
}
.sidebar-heading {
    color: #fff;
    border-left: var(--link-border-width) solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar-heading small {
    margin-left: auto;
}
.sidebar .nav-link span {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* drobečková navigace */
#breadcrumbs,
#breadcrumbs .breadcrumb {
    margin-bottom: 0;
}
#breadcrumbs {
    margin-bottom: 0.5rem;
}
#breadcrumbs .breadcrumb-item.active {
    color: #3D4852; /* grey darkest */
}

footer {
    align-items: center;
    display: flex;
    background-color: #002651;
    color: #fff;
    font-size: 16px;
    min-height: 70px;
}
footer .nav li {
    margin-right: 25px;
}
footer .nav li p {
    margin: 0;
}
/* aby byl footer na krátké stránce až na spodním okraji obrazovky */
#root {
    display: flex;
    flex-direction: column;
}
footer {
    margin-top: auto;
}

#page {
    max-width: 1320px;
    margin: 0 auto;
}